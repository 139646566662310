import { HeadersAccessToken } from '../config'
import api from '../index'

/**
 * => Interface que define as propriedades de uma conta.
 */
interface AccountProps {
    ownerId: string,
    name: string,
    fantasyName: string,
    documentType: any,
    documentNumber: string,
    email: string,
    street: string,
    number: string,
    complement: string,
    neighborhood: string,
    city: string,
    stateCode: string,
    postalCode: string
}

/**
 * => Interface que define os parâmetros para obter contas.
 */
interface GetAccountsProps {
    pageIndex?: number
    pageSize?: number
    accountId?: string
    accountNumber?: string
    ownerId?: string
    ownerName?: string
    ownerDocument?: string
    status?: '' | 'Active' | 'Inactive' | 'Blocked'
    type?: '' | 'checkingAccount' | 'salary' | 'savings' | 'transactional'
}

/**
 * => Função para obter contas com base nos parâmetros fornecidos.
 * @param params Parâmetros para filtragem de contas.
 * @returns Lista de contas ou erro.
 */
export const getAccounts = async (params: GetAccountsProps) => {
    const access_token = `Bearer ${HeadersAccessToken()}`
    const queryParams = {
        pageIndex: params.pageIndex,
        pageSize: params.pageSize,
        accountId: params.accountId,
        accountNumber: params.accountNumber,
        ownerId: params.ownerId,
        ownerName: params.ownerName,
        ownerDocument: params.ownerDocument,
        status: params.status,
        type: params.type
    }

    const urlParams = Object.entries(queryParams)
        .filter(([_, value]) => (typeof value === 'string' ? value.trim() !== '' : value !== undefined && value !== null))
        .map(([key, value]) => `${key}=${encodeURIComponent(value!.toString())}`)
        .join('&')

    const url = `/v1/account/?${urlParams}`

    try {
        return await api.get(url, { headers: { Authorization: access_token } })
    } catch (error: any) {
        return error
    }
}

/**
 * => Função para obter uma conta pelo ID.
 * @param accountId ID da conta.
 * @returns Dados da conta ou erro.
 */
export const getAccountById = async ({ accountId }: { accountId: string }) => {
    const access_token = `Bearer ${HeadersAccessToken()}`
    const url = `/v1/account/${accountId}`
    try {
        return await api.get(url, { headers: { Authorization: access_token } })
    } catch (error: any) {
        return error
    }
}

/**
 * => Interface que define os parâmetros para obter movimentos de conta.
 */
export interface GetAccountMovementsProps {
    accountId: string
    init?: string
    end?: string
    pageIndex?: number
    pageSize?: number
}

/**
 * => Função para obter o extrato de movimentos de uma conta pelo ID.
 * @param params Parâmetros para filtragem dos movimentos.
 * @returns Lista de movimentos ou erro.
 */
export const getAccountMovementsStatementByAccountId = async (params: GetAccountMovementsProps) => {
    const access_token = `Bearer ${HeadersAccessToken()}`
    const queryParams = {
        accountId: params.accountId,
        init: params.init,
        end: params.end,
        pageIndex: params.pageIndex,
        pageSize: params.pageSize,
    }

    const urlParams = Object.entries(queryParams)
        .filter(([_, value]) => (typeof value === 'string' ? value.trim() !== '' : value !== undefined && value !== null))
        .map(([key, value]) => `${key}=${encodeURIComponent(value!.toString())}`)
        .join('&')

    const url = `/v1/accountmovement/statement?${urlParams}`

    try {
        return await api.get(url, { headers: { Authorization: access_token } })
    } catch (error: any) {
        return error
    }
}

export interface AccountSettingsProps {
    allowTransfer: boolean,
    receiptsOnlySameDebtor: boolean,
    maxQrCode: number | null
}

/**
 * => Função para alterar as configurações de Regra da conta.
 * @param AccountId e Settings que serão enviadas por parâmetro e no corpo da requisição.
 * @returns retorno de sucesso (200) ou erro.
 */
export const updateAccountSettings = async ({ accountId, settings }: { accountId: string, settings: AccountSettingsProps | undefined }) => {
    const access_token = `Bearer ${HeadersAccessToken()}`
    const url = `/v1/account/${accountId}`

    try {
        return await api.put(url, settings, { headers: { Authorization: access_token } })
    } catch (error) {
        return error
    }
}


/**
 * => Função para criar uma nova conta.
 * @param account Dados da nova conta.
 * @returns Resultado da criação ou erro.
 */
export const createAccount = async (account: AccountProps) => {
    const access_token = `Bearer ${HeadersAccessToken()}`
    const url = `/v1/account`
    try {
        return await api.post(url, account, { headers: { Authorization: access_token } })
    } catch (error: any) {
        return error
    }
}

/**
 * => Interface que define os parâmetros para obter a atividade da conta.
 */
interface GetAccountActivityProps {
    accountId: string
    pageIndex?: number
    pageSize?: number
}

/**
 * => Função para obter a atividade de uma conta pelo ID.
 * @param params Parâmetros para filtragem da atividade da conta.
 * @returns Atividade da conta ou erro.
 */
export const getAccountActivityById = async (params: GetAccountActivityProps) => {
    const access_token = `Bearer ${HeadersAccessToken()}`
    const queryParams = {
        pageIndex: params.pageIndex,
        pageSize: params.pageSize
    }

    const urlParams = Object.entries(queryParams)
        .filter(([_, value]) => value !== undefined && value !== null)
        .map(([key, value]) => `${key}=${encodeURIComponent(value!.toString())}`)
        .join('&')

    const url = `/v1/account/${params.accountId}/activity?${urlParams}`

    try {
        return await api.get(url, { headers: { Authorization: access_token } })
    } catch (error: any) {
        return error
    }
}

// Novas funções para Risk-Rules associadas a contas

/**
 * => Interface que define as propriedades de uma regra de risco associada a uma conta.
 */
export interface AccountRiskRuleProps {
    riskRuleId?: string
    scope: string
    type: string
    name: string
    description?: string
    expression: string
    action: string
    createDate?: string
    updateDate?: string
    active?: boolean
}

/**
 * => Interface que define os parâmetros para obter regras de risco associadas a uma conta.
 */
export interface GetAccountRiskRulesProps {
    id?: string
    scope?: string
    type?: string
    name?: string
    expression?: string
    action?: string
    createDateStart?: string
    createDateEnd?: string
    updateDateStart?: string
    updateDateEnd?: string
    pageSize?: number
    pageIndex?: number
}

/**
 * => Função para obter regras de risco associadas a uma conta com base nos parâmetros fornecidos.
 * @param accountId ID da conta.
 * @param params Parâmetros para filtragem de regras de risco.
 * @returns Lista de regras de risco ou erro.
 */
export const getAccountRiskRules = async (accountId: string, params: GetAccountRiskRulesProps) => {
    const access_token = `Bearer ${HeadersAccessToken()}`
    const queryParams = {
        id: params.id,
        scope: params.scope,
        type: params.type,
        name: params.name,
        expression: params.expression,
        action: params.action,
        createDateStart: params.createDateStart,
        createDateEnd: params.createDateEnd,
        updateDateStart: params.updateDateStart,
        updateDateEnd: params.updateDateEnd,
        pageSize: params.pageSize,
        pageIndex: params.pageIndex
    }

    const urlParams = Object.entries(queryParams)
        .filter(([_, value]) => (typeof value === 'string' ? value.trim() !== '' : value !== undefined && value !== null))
        .map(([key, value]) => `${key}=${encodeURIComponent(value!.toString())}`)
        .join('&')

    const url = `/v1/account/${accountId}/riskrule/?${urlParams}`

    try {
        return await api.get(url, { headers: { Authorization: access_token } })
    } catch (error: any) {
        return error
    }
}

/**
 * => Função para obter uma regra de risco associada a uma conta pelo ID.
 * @param accountId ID da conta.
 * @param riskRuleId ID da regra de risco.
 * @returns Dados da regra de risco ou erro.
 */
export const getAccountRiskRuleById = async ({ accountId, riskRuleId }: { accountId: string | undefined, riskRuleId: string | undefined }) => {
    const access_token = `Bearer ${HeadersAccessToken()}`
    const url = `/v1/account/${accountId}/riskrule/${riskRuleId}`
    try {
        return await api.get(url, { headers: { Authorization: access_token } })
    } catch (error: any) {
        return error
    }
}

/**
 * => Função para criar uma nova regra de risco associada a uma conta.
 * @param accountId ID da conta.
 * @param data Dados da nova regra de risco.
 * @returns Resultado da criação ou erro.
 */
export const createAccountRiskRule = async ({ accountId, data }: { accountId: string, data: AccountRiskRuleProps }) => {
    const access_token = `Bearer ${HeadersAccessToken()}`
    const url = `/v1/account/${accountId}/riskrule`
    try {
        return await api.post(url, data, { headers: { Authorization: access_token } })
    } catch (error: any) {
        return error
    }
}

/**
 * => Função para atualizar uma regra de risco associada a uma conta pelo ID.
 * @param accountId ID da conta.
 * @param riskRuleId ID da regra de risco.
 * @param data Dados atualizados da regra de risco.
 * @returns Resultado da atualização ou erro.
 */
export const updateAccountRiskRule = async ({ accountId, riskRuleId, data }: { accountId: string | undefined, riskRuleId: string | undefined, data: any }) => {
    const access_token = `Bearer ${HeadersAccessToken()}`
    const url = `/v1/account/${accountId}/riskrule/${riskRuleId}`
    try {
        return await api.put(url, data, { headers: { Authorization: access_token } })
    } catch (error: any) {
        return error
    }
}

/**
 * => Função para ativar uma regra de risco associada a uma conta pelo ID.
 * @param accountId ID da conta.
 * @param riskRuleId ID da regra de risco.
 * @returns Resultado da ativação ou erro.
 */
export const activateAccountRiskRule = async ({ accountId, riskRuleId }: { accountId: string | undefined, riskRuleId: string | undefined }) => {
    const access_token = `Bearer ${HeadersAccessToken()}`
    const url = `/v1/account/${accountId}/riskrule/${riskRuleId}/active`
    try {
        return await api.put(url, {}, { headers: { Authorization: access_token } })
    } catch (error: any) {
        return error
    }
}

/**
 * => Função para desativar uma regra de risco associada a uma conta pelo ID.
 * @param accountId ID da conta.
 * @param riskRuleId ID da regra de risco.
 * @returns Resultado da desativação ou erro.
 */
export const deactivateAccountRiskRule = async ({ accountId, riskRuleId }: { accountId: string | undefined, riskRuleId: string | undefined }) => {
    const access_token = `Bearer ${HeadersAccessToken()}`
    const url = `/v1/account/${accountId}/riskrule/${riskRuleId}/inactive`
    try {
        return await api.put(url, {}, { headers: { Authorization: access_token } })
    } catch (error: any) {
        return error
    }
}

/**
 * => Interface que define os parâmetros para a liquidação de uma conta.
 */
export interface AccountSettlementProps {
    settlementAccountId: string
    amount: number
}

/**
 * => Função para realizar a liquidação de uma conta.
 * @param accountId ID da conta.
 * @param data Dados da liquidação (settlementAccountId e amount).
 * @returns Resultado da liquidação ou erro.
 */
export const settleAccount = async ({ accountId, data }: { accountId: string, data: AccountSettlementProps }) => {
    const access_token = `Bearer ${HeadersAccessToken()}`
    const url = `/v1/account/${accountId}/settlement`

    try {
        return await api.post(url, data, { headers: { Authorization: access_token } })
    } catch (error: any) {
        return error
    }
}

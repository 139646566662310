import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { getUserTimezoneOffset } from "../../../../../utils/storage/auth"
import { formatToBRL } from "../../../../../utils/helpers/formated"
import { getPath } from "../../../../../utils/helpers/path"
import { convertUTCToUserTimezone } from "../../../../../utils/helpers/date"
import { getAccountMovementsStatementByAccountId } from "../../../../../api/endpoints/account"
import { Skeleton } from "../../../../../components/ui/skeleton"
import { PlatformTemplate } from "../../../../../components/layout/template"
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../../../components/ui/table"
import { Input } from "../../../../../components/ui/input"
import { TabsAccount } from "../_components/tabs-account"

export default function AccountExtract() {
  const [extracts, setExtracts] = useState<any>()
  const [initDate, setInitDate] = useState<string>("")
  const [endDate, setEndDate] = useState<string>("")

  const fetchExtractMovementByAccountId = async () => {
    const accountId = getPath()
    const response: any = await getAccountMovementsStatementByAccountId({
      accountId: accountId,
      init: initDate,
      end: endDate
    })

    if (response?.data?.items) {
      setExtracts(response?.data?.items)
    }
  }

  useEffect(() => {
    fetchExtractMovementByAccountId()
  }, [initDate, endDate])

  return (
    <PlatformTemplate>
      <div className="h-full w-full flex justify-center">
        <div className="h-full w-3/4 flex flex-col gap-5">
          <TabsAccount value="extract" />
          <div className="w-full h-full flex flex-col gap-5">
            <div className="flex gap-2">
              <Input
                type="date"
                value={initDate}
                onChange={(e) => setInitDate(e.target.value)}
                className="p-2 border"
              />
              <Input
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                className="p-2 border"
              />
            </div>
            {extracts ? <AccountExtractContent extracts={extracts} /> : <Skeleton className="w-full h-full" />}
          </div>
        </div>
      </div>
    </PlatformTemplate>
  )
}

const AccountExtractContent = ({ extracts }: { extracts: any }) => {
  const navigate = useNavigate()

  interface tProps {
    key: string
    value: string
  }

  const t: tProps[] = [
    {
      key: 'Consolidate',
      value: 'Saldo do dia'
    },
    {
      key: 'QRCodeDynamic',
      value: 'QRCode Dinâmico'
    }
  ]

  return (
    <div className="w-full h-[550px] overflow-y-auto flex flex-col gap-5">
      <Table>
        <TableCaption>Extrato associado a esta conta.</TableCaption>
        <TableHeader>
          <TableRow>
            <TableHead>Data</TableHead>
            <TableHead>Descrição</TableHead>
            <TableHead>Valor</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {extracts.map((extract: any, index: number) => (
            <TableRow
              key={index}
              className={`${extract.description === 'Consolidate' ? 'cursor-not-allowed font-bold text-blue-800' : 'cursor-pointer'}`}
              onClick={() => extract.description === 'Consolidate' ? {} : navigate(`/transaction/details/${extract.transactionId}`)}
            >
              <TableCell className="font-medium">{convertUTCToUserTimezone(extract.dateTime, getUserTimezoneOffset())}</TableCell>
              <TableCell className="font-medium">
                {t.map((translation, index) => {
                  if (extract.description === translation.key) {
                    return (
                      <p key={index}>{translation.value}</p>
                    )
                  }
                })}
              </TableCell>
              <TableCell>{formatToBRL(extract.amount)}</TableCell>
              <TableCell>{extract.type}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  )
}

import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { formatToBRL } from "../../../../../utils/helpers/formated"
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../../../../components/ui/dialog"
import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectGroup,
  SelectLabel,
  SelectItem
} from "../../../../../components/ui/select"
import { Input } from "../../../../../components/ui/input"
import { Loader2 } from "lucide-react"
import {
  getAccountActivityById,
  getAccountById,
  getAccounts,
  settleAccount
} from "../../../../../api/endpoints/account"
import { getPath } from "../../../../../utils/helpers/path"
import {
  Card,
  CardContent,
  CardDescription,
  CardTitle,
} from "../../../../../components/ui/card"
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../../../../components/ui/tooltip"
import {
  getOwnersById,
  getSettlementAccountsByOwnerId
} from "../../../../../api/endpoints/owner"
import { getUserTimezoneOffset } from "../../../../../utils/storage/auth"
import { Skeleton } from "../../../../../components/ui/skeleton"
import { Switch } from "../../../../../components/ui/switch"
import { Button } from "../../../../../components/ui/button"
import { Copy, Wallet } from "lucide-react"
import { convertUTCToUserTimezone } from "../../../../../utils/helpers/date"
import { PlatformTemplate } from "../../../../../components/layout/template"
import { TabsAccount } from "../_components/tabs-account"
import { AccountProps } from "../../page"
import Swal from "sweetalert2"

type SettlementAccountProps = {
  settlementAccountId: string
  name: string
  documentNumber: string
  documentType: string
  accountNumber: string
  branchNumber: string
  accountType: string
  bankIspb: string
  bankCode: string | null
  bankName: string | null
  createDate: string
  updateDate: string
}

export default function AccountDetails() {
  const [account, setAccount] = useState<any>()
  const [latestActivities, setLatestActivities] = useState<any>()
  // => Variáveis de manipulação de funcionamento de Transferência
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [selectedAccountId, setSelectedAccountId] = useState<string | null>(null)
  const [accountSelected, setAccountSelected] = useState<AccountProps>()
  const [isLoadingDialog, setIsLoadingDialog] = useState<boolean>(true)
  const [transferAmount, setTransferAmount] = useState<number>(0)
  const [transferSettlementAccount, setTransferSettlementAccount] = useState<string>('')
  const [isTransferSubmit, setIsTransferSubmit] = useState<boolean>(false)
  const [transferOwner, setTransferOwner] = useState()
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false)
  const [transferSettlementAccounts, setTransferSettlementAccounts] = useState<SettlementAccountProps[]>([])
  const [rawValue, setRawValue] = useState<number>(0)
  const [ownerId, setOwnerId] = useState()

  const fetchActivitiesByAccount = async () => {
    const response: any = await getAccountActivityById({ accountId: getPath() })
    setLatestActivities(response.data.items.slice(0, 5))
  }

  const fetchAccountById = async () => {
    const accountId = getPath()
    const response: any = await getAccountById({ accountId: accountId })
    setAccount(response?.data)
  }

  const fetchTransferGetData = async (id: string, ownerId: string) => {
    setTransferAmount(0)
    setIsLoadingDialog(true)
    const response = await getAccountById({ accountId: id })
    if (response?.data) {
      setAccountSelected(response.data)
    } else {
      setAccountSelected(undefined)
    }

    const responseOwner = await getOwnersById(ownerId)
    if (responseOwner.data) {
      setTransferOwner(responseOwner.data)
    } else {
      setTransferOwner(undefined)
    }

    const responseSettlementAccounts = await getSettlementAccountsByOwnerId(ownerId)
    if (responseSettlementAccounts.data) {
      setTransferSettlementAccounts(responseSettlementAccounts.data)
    } else {
      setTransferSettlementAccounts([])
    }

    return setIsLoadingDialog(false)
  }

  const handleTransfer = async () => {
    setIsTransferSubmit(true)
    const response = await settleAccount({
      accountId: String(selectedAccountId),
      data: {
        amount: transferAmount,
        settlementAccountId: transferSettlementAccount
      }
    })

    if (response.data.isSucceeded) {
      Swal.fire({
        icon: "success",
        title: "Transferência solicitada!",
        confirmButtonColor: "var(--gray-500)",
      })
    } else {
      Swal.fire({
        icon: "error",
        title: "Não foi possível realizar a solicitação de transferência.",
        confirmButtonColor: "var(--gray-500)",
      })
    }

    setIsDialogOpen(false)
    setIsTransferSubmit(false)
    setTransferOwner(undefined)
    setAccountSelected(undefined)
    setTransferSettlementAccount('')
    setTransferSettlementAccounts([])
    fetchAccountById()
    fetchActivitiesByAccount()
  }

  const selectedBank = transferSettlementAccounts.find(
    (bank) => bank.settlementAccountId === transferSettlementAccount
  )

  useEffect(() => {
    fetchAccountById()
    fetchActivitiesByAccount()
  }, [])

  return (
    <PlatformTemplate>
      <div className="h-full w-full flex justify-center">
        <div className="h-full w-3/4 flex flex-col gap-5">
          <TabsAccount value="details" />
          {account && latestActivities ?
            <AccountDetailsContent
              accountId={getPath()}
              account={account}
              latestActivities={latestActivities}
              isDialogOpen={isDialogOpen}
              setIsDialogOpen={setIsDialogOpen}
              isLoadingDialog={isLoadingDialog}
              accountSelected={accountSelected}
              transferSettlementAccount={transferSettlementAccount}
              transferSettlementAccounts={transferSettlementAccounts}
              rawValue={rawValue}
              setRawValue={setRawValue}
              transferAmount={transferAmount}
              setTransferAmount={setTransferAmount}
              setTransferSettlementAccount={setTransferSettlementAccount}
              handleTransfer={handleTransfer}
              selectedBank={selectedBank}
              isConfirmDialogOpen={isConfirmDialogOpen}
              setIsConfirmDialogOpen={setIsConfirmDialogOpen}
              isTransferSubmit={isTransferSubmit}
              fetchTransferGetData={fetchTransferGetData}
              selectedAccountId={selectedAccountId}
              setSelectedAccountId={setSelectedAccountId}
            />
            : <Skeleton className="w-full h-full" />
          }
        </div>
      </div>
    </PlatformTemplate>
  )
}

const AccountDetailsContent = ({
  accountId,
  account,
  latestActivities,
  isDialogOpen,
  setIsDialogOpen,
  isLoadingDialog,
  accountSelected,
  transferSettlementAccount,
  transferSettlementAccounts,
  rawValue,
  setRawValue,
  transferAmount,
  setTransferAmount,
  setTransferSettlementAccount,
  handleTransfer,
  selectedBank,
  isConfirmDialogOpen,
  setIsConfirmDialogOpen,
  isTransferSubmit,
  fetchTransferGetData,
  selectedAccountId,
  setSelectedAccountId
}: {
  accountId: string
  account: any,
  latestActivities: any,
  isDialogOpen: boolean,
  setIsDialogOpen: (isOpen: boolean) => void,
  isLoadingDialog: boolean,
  accountSelected: any,
  transferSettlementAccount: string,
  transferSettlementAccounts: any[],
  rawValue: number,
  setRawValue: (value: number) => void
  transferAmount: number,
  setTransferAmount: (value: number) => void
  setTransferSettlementAccount: (value: string) => void,
  handleTransfer: () => void,
  selectedBank: any,
  isConfirmDialogOpen: boolean,
  setIsConfirmDialogOpen: (isOpen: boolean) => void,
  isTransferSubmit: boolean,
  fetchTransferGetData: (id: string, ownerId: string) => Promise<void>
  selectedAccountId: any
  setSelectedAccountId: (value: any) => void
}) => {
  const navigate = useNavigate()

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value.replace(/\D/g, '')
    const numericValue = Number(inputValue)

    if (accountSelected?.balance !== undefined && numericValue > accountSelected.balance) {
      setRawValue(accountSelected.balance)
      setTransferAmount(accountSelected.balance)
    } else {
      setRawValue(numericValue)
      setTransferAmount(numericValue)
    }
  }

  const handleCopyPixCode = () => {
    const pixKey = account.keys && account.keys.length > 0 ? account.keys[0].key : ''
    navigator.clipboard.writeText(pixKey)
  }

  return (
    <div className="w-full flex flex-col gap-5">
      <div className="w-full flex items-center justify-between">
        <h1 className="text-[20px] font-semibold">Saldo:</h1>
        <p className="text-[20px] font-semibold">{formatToBRL(account.balance)}</p>
      </div>
      <Card>
        <CardContent className="flex items-center justify-between py-3 px-5">
          <div className="flex items-center justify-center gap-1">
            <h3>Agência:</h3>
            <p className="font-semibold">{account.branch}</p>
          </div>
          <div className="flex flex-col gap-5 items-center justify-center my-2">
            <div className="flex items-center justify-center gap-1">
              <h3>Conta:</h3>
              <p className="font-semibold">{account.accountNumber}</p>
            </div>
            <Button
              onClick={() => {
                setSelectedAccountId(getPath())
                setIsDialogOpen(true)
                fetchTransferGetData(getPath(), account.ownerId)
              }}
              className="gap-2 px-5"
              variant='outline'
            >
              Transferir
              <Wallet size={15} className="mr-2" />
            </Button>
          </div>
        </CardContent>
      </Card>
      <Card>
        <CardContent className="w-full flex flex-col items-center justify-between py-10 px-5 gap-5">
          {account.keys && account.keys.length > 0 ?
            <div className="w-full flex items-center justify-between">
              <h3 className="font-semibold">Chave:</h3>
              <Button
                variant="ghost"
                className="flex items-center gap-3 cursor-pointer"
                onClick={handleCopyPixCode}
              >
                <p>{account.keys[0].key}</p>
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <Copy className="cursor-pointer" size={20} />
                    </TooltipTrigger>
                    <TooltipContent>
                      <p>Copiar Chave PIX</p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              </Button>
            </div>
            : ''}
          <div className="w-full flex items-center justify-between">
            <h3 className="font-semibold">Tipo:</h3>
            <p>{account.keys && account.keys.length > 0 ? account.keys[0].type : ''}</p>
          </div>
          <div className="w-full flex items-center justify-between">
            <h3 className="font-semibold">Ativo:</h3>
            <Switch checked={account.status === "Active"} id="airplane-mode" />
          </div>
        </CardContent>
      </Card>
      <div className="w-full grid grid-cols-[2fr_3fr] gap-5">
        <Card className="hover:bg-gray-50">
          <CardContent
            onClick={() => navigate(`/owner/details/${account.ownerId}`)}
            className="flex flex-col gap-5 py-10 px-8 cursor-pointer"
          >
            <h2 className="font-semibold">Cliente</h2>
            <div className="flex flex-col gap-1">
              <h3 className="font-semibold">Razão Social</h3>
              <p>{account.ownerName}</p>
            </div>
            <div className="flex flex-col gap-1">
              <h3 className="font-semibold">Documento</h3>
              <p>{account.ownerDocument}</p>
            </div>
          </CardContent>
        </Card>
        <Card>
          <CardContent className="h-[250px] flex flex-col gap-5 p-5 overflow-y-auto">
            <CardTitle>Últimas Atividades</CardTitle>
            <div className="w-full flex flex-col gap-3 overflow-y-auto">
              {latestActivities.map((item: any) => (
                <Card>
                  <CardContent className="flex flex-col gap-2 p-2">
                    <div className="flex items-center justify-between">
                      <CardTitle>{convertUTCToUserTimezone(item.createDate, getUserTimezoneOffset())}</CardTitle>
                      <CardDescription className="text-medium">{item.userName}</CardDescription>
                    </div>
                    <CardDescription>{item.description}</CardDescription>
                  </CardContent>
                </Card>
              ))}
            </div>
          </CardContent>
        </Card>
      </div>
      <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
        <DialogContent className="sm:max-w-[500px] min-h-[350px]">
          {!isLoadingDialog ?
            <div className="w-full h-full flex flex-col justify-between">
              <DialogHeader>
                <DialogTitle className="text-lg">Realizar transferência</DialogTitle>
                <DialogDescription className="h-16 flex flex-col items-start justify-center">
                  <div className="flex items-center justify-center gap-1">
                    <p className="font-bold">Agência:</p>
                    <p>{accountSelected?.branch}</p>
                  </div>
                  <div className="flex items-center justify-center gap-1">
                    <p className="font-bold">Conta:</p>
                    <p>{accountSelected?.accountNumber}</p>
                  </div>
                </DialogDescription>
              </DialogHeader>
              <div className="grid gap-6 py-4">
                <div>
                  <p className="text-emerald-800 font-bold text-sm">Saldo:</p>
                  <p className="text-emerald-600 font-semibold text-2xl">{formatToBRL(Number(accountSelected?.balance))}</p>
                </div>
                <div className="flex flex-col gap-3">
                  <p>Transferir para:</p>
                  <Select
                    disabled={isTransferSubmit}
                    value={transferSettlementAccount}
                    onValueChange={(event) => setTransferSettlementAccount(event)}
                  >
                    <SelectTrigger className="w-2/3">
                      <SelectValue placeholder="Selecione um banco:" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectGroup>
                        <SelectLabel>Outros bancos:</SelectLabel>
                        {transferSettlementAccounts.map((bank: any) => (
                          <SelectItem value={bank.settlementAccountId}>{bank.name}</SelectItem>
                        ))}
                      </SelectGroup>
                    </SelectContent>
                  </Select>
                </div>
                <div className="flex items-center justify-between my-5">
                  <p className="text-neutral-500 text-sm">Valor de transferência:</p>
                  <Input
                    disabled={isTransferSubmit || Number(accountSelected?.balance) === 0}
                    className="w-1/2"
                    value={rawValue ? formatToBRL(rawValue) : ''}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <DialogFooter className="mt-10">
                <Button disabled={isTransferSubmit} variant="outline" onClick={() => setIsDialogOpen(false)}>
                  Cancelar
                </Button>
                <Button
                  disabled={
                    isTransferSubmit ||
                    Number(accountSelected?.balance) === 0 ||
                    transferAmount <= 0 ||
                    !transferSettlementAccount
                  }
                  className="bg-emerald-600"
                  onClick={() => setIsConfirmDialogOpen(true)}
                >
                  {isTransferSubmit
                    ? <span className="flex items-center justify-center gap-2">
                      <Loader2 className="animate-spin" size={15} />
                      Solicitando transferência...
                    </span>
                    : 'Confirmar'
                  }
                </Button>
              </DialogFooter>
            </div>
            :
            <div className="w-full min-h-[350px] flex flex-col gap-3 items-center justify-center text-emerald-600">
              <Loader2 className="animate-spin" size={60} />
              <p className="w-[250px] text-center text-lg">Carregando informações de transferência...</p>
            </div>
          }
        </DialogContent>
      </Dialog>
      <Dialog open={isConfirmDialogOpen} onOpenChange={setIsConfirmDialogOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Confirmar Transferência</DialogTitle>
            <DialogDescription>
              <p className="text-sm font-medium text-gray-600">
                Você tem certeza que deseja realizar a transferência no valor de{" "}
                <strong className="text-green-500">{formatToBRL(transferAmount)}</strong>?
              </p>
              {selectedBank && (
                <div className="mt-5 rounded-md border border-gray-200 bg-gray-50 p-3 shadow-sm">
                  <p className="text-sm font-semibold text-gray-700 mb-3">Detalhes do Destinatário</p>
                  <div className="space-y-1 text-xs text-gray-600">
                    <p>
                      <strong className="font-medium text-gray-800">Destinatário:</strong> {selectedBank.name}
                    </p>
                    <p>
                      <strong className="font-medium text-gray-800">Documento:</strong>{" "}
                      {selectedBank.documentType} - {selectedBank.documentNumber}
                    </p>
                    <p>
                      <strong className="font-medium text-gray-800">Conta:</strong>{" "}
                      {selectedBank.accountNumber}
                    </p>
                    <p>
                      <strong className="font-medium text-gray-800">Agência:</strong>{" "}
                      {selectedBank.branchNumber}
                    </p>
                    <p>
                      <strong className="font-medium text-gray-800">Tipo de Conta:</strong>{" "}
                      {selectedBank.accountType}
                    </p>
                    <p>
                      <strong className="font-medium text-gray-800">Banco:</strong>{" "}
                      {selectedBank.bankName || <span className="italic text-gray-400">Não especificado</span>}
                    </p>
                  </div>
                </div>
              )}
            </DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <Button
              variant="outline"
              onClick={() => setIsConfirmDialogOpen(false)}
            >
              Cancelar
            </Button>
            <Button
              className="bg-emerald-600"
              onClick={() => {
                handleTransfer();
                setIsConfirmDialogOpen(false);
              }}
            >
              Confirmar
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  )
}